import Vue from "vue";
import VueI18Next from "@panter/vue-i18next";
import i18next from "i18next";

Vue.use(VueI18Next);

const i18n = new VueI18Next(i18next);
const gatewayLabels = {
  asia_swift: "China Union Pay",
  tffchinaunion: "China Union Pay",
  bank: i18n.t("bank-transfer"),
  blockcypher: "Blockcypher",
  paypal: "PayPal",
  paysec_WECHAT: "WECHAT",
  paysec_BANK_TRANSFER: i18n.t("paysec-bank-transfer"),
  paysec_QQPAY: "QQPay",
  mygate: i18n.t("credit-debit-card"),
  gpaysafe: i18n.t("credit-debit-card"),
  sorexpay: i18n.t("credit-debit-card"),
  paymentasia: "China Union Pay",
  solidpayments: i18n.t("credit-debit-card"),
  ecommpay: "Ecommpay",
  orangepay: "Orangepay",
  skrill: "Skrill / Moneybookers",
  s4ucash: i18n.t("credit-debit-card"),
  skrill_static: "Skrill / Moneybookers",
  trustpay: i18n.t("credit-debit-card"),
  securetrading: i18n.t("credit-debit-card"),
  neteller_static: "Neteller",
  neteller: "Neteller",
  fasapay: "Fasapay",
  fasapay_static: "Fasapay",
  voguepay_static: "Voguepay",
  perfectmoney_static: "Perfect Money",
  ddbill: "DDBill",
  fxpaygate: i18n.t("debit-card"),
  noirepay: "Noire (including Skrill)",
  worldpay: "Worldpay",
  ipay: "i-Pay",
  perfectmoney: "Perfect Money",
  coingate: "Bitcoin",
  visa: i18n.t("coming-soon"),
  mastercard: i18n.t("coming-soon"),
  sepa: "SEPA",
  payza: i18n.t("coming-soon"),
  iaccount: "i-Account",
  ipayment: i18n.t("coming-soon"),
  lionpayment: i18n.t("coming-soon"),
  okpay: i18n.t("coming-soon"),
  rpn: "RPN pay",
  cupgeneric: "ICM Payment Gateway",
  safecharge: "Safecharge",
  axcess: "Axcess",
  icmcup: "ICM Payment Gateway (Generic)",
};

export default gatewayLabels